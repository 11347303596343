import { db } from "../../firebase/firebaseConfig";
// import firebase from "firebase";

export default {
  // Fetch emails
  getPages({ commit }) {
    return new Promise((resolve, reject) => {
      const pages = [];
      db.collection("pages-data")
        .get()
        .then((response) => {
          response.forEach((res) => {
            const data = res.data();
            const page = {
              id: res.id,
              background_image: data.background_image,
              description: data.description,
              title: data.title,
              page: data.page,
              image_name: data.image_name,
            };
            pages.push(page);
          });
          commit("SET_PAGES", pages);
          resolve(pages);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePages({ commit }, item) {
    let payload = {
      background_image: item.background_image,
      description: item.description,
      title: item.title,
      image_name: item.image_name,
    };
    return new Promise((resolve, reject) => {
      db.collection("pages-data")
        .doc(item.id)
        .update(payload)
        .then((response) => {
          commit("UPDATE_PAGES", item);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
