import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import LandingModule from "./landing/index.js"
import UsersModule from "./users/index.js"
import AdminModule from "./admin/index.js"
// Install vuex
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    LandingModule,
    UsersModule,
    AdminModule
  },
  plugins: [createPersistedState()],
});
