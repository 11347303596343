// import { db } from "../../firebase/firebaseConfig";
import firebase from "firebase";

export default {
  // Fetch emails
  signUp({ commit }, data) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          commit("SET_USER", user);
          resolve(user);
          // ...
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signOut({ commit }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Signed out
        var user = null;
        commit("SET_USER", user);
        // ...
      })
      .catch((error) => {
        console.log(error)
      });
  },
};
