import state from "./landingState.js";
import mutations from "./landingMutation.js";
import actions from "./landingActions.js";
import getters from "./landingGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
