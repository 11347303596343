import { db } from "../../firebase/firebaseConfig";
// import firebase from "firebase";

export default {
  // Fetch emails
  getSections({ commit }) {
    return new Promise((resolve, reject) => {
      const sections = [];
      db.collection("home-sections")
        .get()
        .then((response) => {
          response.forEach((res) => {
            const data = res.data();
            const section = {
              id: res.id,
              background_image: data.background_image,
              button_name: data.button_name,
              pdf_url: data.pdf_url,
              subtitle: data.subtitle,
              title: data.title,
              type_banner: data.type_banner,
              image_name: data.image_name,
              file_name: data.file_name,
            };
            if (data.instagram_url) {
              section.instagram_url = data.instagram_url;
            }
            if (data.facebook_url) {
              section.facebook_url = data.facebook_url;
            }
            if (data.whatsapp_url) {
              section.whatsapp_url = data.whatsapp_url;
            }
            sections.push(section);
          });
          commit("SET_SECTIONS", sections);
          resolve(sections);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateSections({ commit }, item) {
    let payload = {
      background_image: item.background_image,
      button_name: item.button_name,
      pdf_url: item.pdf_url,
      subtitle: item.subtitle,
      title: item.title,
      type_banner: item.type_banner,
    };
    if (item.instagram_url) {
      payload.instagram_url = item.instagram_url;
      payload.facebook_url = item.facebook_url;
      payload.whatsapp_url = item.whatsapp_url;
    }
    if (item.file_name != undefined) {
      payload.file_name = item.file_name;
    }
    if (item.image_name != undefined) {
      payload.image_name = item.image_name;
    }

    return new Promise((resolve, reject) => {
      db.collection("home-sections")
        .doc(item.id)
        .update(payload)
        .then((response) => {
          commit("UPDATE_SECTIONS", item);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
