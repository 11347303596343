import state from "./adminState.js";
import mutations from "./adminMutation.js";
import actions from "./adminActions.js";
import getters from "./adminGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};