export default {
  SET_SECTIONS(state, sections) {
    state.sections = sections;
  },
  UPDATE_SECTIONS(state, user) {
    const SectionsIndex = state.sections.findIndex((p) => p.id === user.id);
    Object.assign(state.sections[SectionsIndex], user);
  },

};
