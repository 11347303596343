import Vue from "vue";
import VueRouter from 'vue-router'
import firebase from "firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import("@/views/Landing/Home/Index.vue"),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import("@/views/Landing/About/Index.vue")
  },
  {
    path: '/distribution',
    name: 'Distribution',
    component: () => import("@/views/Landing/Distribution/Index.vue")
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import("@/views/Admin/Auth/Index.vue")
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: { requiresAuth: true },
    component: () => import("@/layouts/AdminLayout/Index.vue"),
    children: [
      {
        path: "",
        name: "Uploader",
        meta: { requiresAuth: true },
        component: () => import("@/views/Admin/Uploader/Index.vue"),
      },
      {
        path: "pages",
        name: "Pages",
        meta: { requiresAuth: true },
        component: () => import("@/views/Admin/Pages/Index.vue"),
      },
      {
        path: "about-us",
        name: "AboutUs",
        meta: { requiresAuth: true },
        component: () => import("@/views/Admin/aboutUs/index.vue"),
      },
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      alert('You must be logged in to see this page');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router;
