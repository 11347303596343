<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss">
@import "./scss/_colors.scss";

.input-bluexpress-component {
  .vs-input {
    width: 100%;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: $light;
  }

  .vs-input:focus {
    border: 2px solid #056ee0;
  }

  .vs-input:focus ~ .vs-input__label {
    color: #056ee0;
  }

  .vs-input:hover {
    border: 2px solid #056ee0;
  }
  .input-hackmetrix-component-outline {
    border: none !important;
  }

  .vs-input__icon--after {
    right: 10px;
    background: transparent;
    box-shadow: none;
  }

  .vs-input:focus ~ .vs-input__icon--after {
    -webkit-transform: none;
    transform: none;
    background: transparent;
  }
}

.title-box {
  font-family: Poppins;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  color: #2c2c2c;
}

.upload-text {
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #0276fc;
  cursor: pointer;
}
</style>
