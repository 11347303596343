import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCrelJyGsEVLUKmhOjgRllKWofmZlWeU5g",
  authDomain: "blueexpress-a9420.firebaseapp.com",
  projectId: "blueexpress-a9420",
  storageBucket: "blueexpress-a9420.appspot.com",
  messagingSenderId: "389230110694",
  appId: "1:389230110694:web:3522aeee9a7c43ceba9827",
  measurementId: "G-WTW0CS3LRR",
};

firebase.initializeApp(firebaseConfig);
// Initialize Firebase
const firebaseFirestore = firebase.firestore();

const db = firebaseFirestore;
export { firebaseFirestore, db };
