import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
// Libraries
import "./assets/styles/index.css";
import "./scss/main.scss";
import Vuesax from "vuesax";
import Vue2Editor from "vue2-editor";
import { store } from "./store/store";
import firebase from "firebase";

import "vuesax/dist/vuesax.css"; //Vuesax styles

// Firebase
import "@/firebase/firebaseConfig";

//Vue2Editor
Vue.use(Vue2Editor);

Vue.use(Vuesax, {
  colors: {
    primary: "#0276ff",
    secondary: "#f3f6f9",
    success: "#94E5B5",
    warning: "#FFBA7D",
    danger: "#F27D7D",
    info: "#2CD5DB",
    white: "#fff",
  },
  // options here
});

///Uso onAuthStateChanged para verificarsi sigue conectado
firebase.auth().onAuthStateChanged(() => {
  //firebase.auth().onAuthStateChanged((user) => {
  // if (user) {
  //   console.log('usuario detectado', user);
  // } else {
  //   console.log('usuario no detectado', user);
  // }

  ///meto la creacion de Vue dentro del observer
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");

  ///
});
