import state from "./usersState.js";
import mutations from "./usersMutation.js";
import actions from "./usersActions.js";
import getters from "./usersGetters.js";

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
