export default {
  SET_PAGES(state, pages) {
    state.pages = pages;
  },
  UPDATE_PAGES(state, user) {
    const PagesIndex = state.pages.findIndex((p) => p.id === user.id);
    Object.assign(state.pages[PagesIndex], user);
  },

};
